
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {Container,Divider,Header,Icon,Menu,Responsive,Segment,Sidebar,Visibility,} from 'semantic-ui-react'

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container text style={{backgroundColor:'rgba(39,39,39,0.5)',paddingBottom:'1em'}}>
    <Header
      as='h1'
      content='Blue Wizard Labs'
      inverted
      style={{
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '3em',
      }}
    />
    <Header
      as='h2'
      content='Using computer sorcery for a better world'
      inverted
      style={{
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.5em',
      }}
    />
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign='center'
            style={{ 
                minHeight: 700, 
                padding: '0em 0em',
                backgroundImage:'url("/dollar-gill-0V7_N62zZcU-unsplash.jpg")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
    
            }}
            vertical
          >
              <Segment inverted>
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                <Menu.Item as='a' active>
                  Home
                </Menu.Item>
                <Menu.Item as='a' href='#Company'>Company</Menu.Item>
                <Menu.Item as='a' href='#Projects'>Projects</Menu.Item>
              </Container>
            </Menu>
            </Segment>
            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation='push'
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as='a' active>
            Home
          </Menu.Item>
          <Menu.Item as='a' href='#Company'>Company</Menu.Item>
          <Menu.Item as='a' href='#Projects'>Projects</Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign='center'
            style={{ 
                minHeight: 350, 
                padding: '1em 0em',
                backgroundImage:'url("/dollar-gill-0V7_N62zZcU-unsplash.jpg")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'

            }}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size='large' style={{ border:0}}>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading mobile />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const HomepageLayout = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Container text id='Company'>          
        <Header as='h3' style={{ fontSize: '2em' }}>
          About the company
        </Header>
        <p style={{ fontSize: '1.33em' }}>
            Blue Wizard Labs is a North Carolina company with over 20 years of software development experience.
            We are focused on building applications that enable people to pursue what they want in life, rather
            than what life has boxed them into.
        </p>

        <Divider
          as='h4'
          className='header'
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase' }}
        >
          <a href='#'>Our Values</a>
        </Divider>

        <Header as='h3' style={{ fontSize: '2em' }}>
          Do no evil
        </Header>
        <p style={{ fontSize: '1.33em' }}>
            It is our core belief that it is possible to run a business that generates revenue without
            exploiting others, and continue to support the rights of users to protect their privacy. Business
            should not profit from the misery of others. It should empower people to better their lives.
        </p>

        <Divider
          as='h4'
          className='header'
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase' }}
          id='Projects'
        >
          <a href='#'>Our Projects</a>
        </Divider>

        <Header as='h3' style={{ fontSize: '2em' }}>
          Cetacean
        </Header>
        <p style={{ fontSize: '1.33em' }}>
            Cetacean is a cloud-based authoring platform for writers. It is currently in development and will be
            going into beta in Spring 2020 with a live launch by early Summer 2020.
        </p>

      </Container>
    </Segment>

    <Segment inverted vertical style={{ padding: '4em 5em' }}>
      <Container>
        <Header inverted as='h4' content='Contact Us' />
        <p>
            Any business inquieries can be sent to <a href="mailto:admin@bluewizardlabs.com">admin@bluewizardlabs.com</a>
        </p>
      </Container>
    </Segment>
  </ResponsiveContainer>
)

export default HomepageLayout;