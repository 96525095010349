import React from 'react';
import HomepageLayout from './HomepageLayout';
import './App.css';

function App() {
  return (
    <HomepageLayout />
  );
}

export default App;
